.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    height: 100svh;
    gap: 10px;
    position: absolute;
    overflow: hidden;
}

.loading {
    font-size: large;
    font-weight: bold;
    padding-top: 5px;
}

.ep-logo {
    width: 200px;
    position: absolute;
}

.dots {
    position: relative;
    right: 20px;
}

.statusContainer {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: #f1f1ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 40px;
    text-align: center;
}

.SUCCESS- {
    background-color: #008037;
    color: #FFF;
}

.FAILED- {
    background-color: #ff5757;
    color: #FFF;
}

.status {
    font-weight: 700;
    font-size: 40px;
}

.info {
    font-weight: 500;
    font-size: 15px;
}