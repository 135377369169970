.carousel {
    padding: 20px 0 0;
    border-radius: 4px;
    flex: 1;
    min-width: 500px;
}

.carousel_img {
    width: 100%;
    margin: 0 auto;
}

.carousel-caption {
    padding: 10px 0;
    height: 120px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.carousel-btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.carousel-btn {
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
    cursor: pointer;
    width: 100px;
}