.settings-box-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    box-shadow: var(--main-box-shadow);
    border-radius: 5px;
    padding: 20px;
}

.settings-box-inner-wrapper-left {
    width: 300px;
    min-width: 300px;
}

.settings-box-inner-wrapper-right {
    display: flex;
    gap: 10px;
    flex: 1;
    flex-direction: column;
    min-width: 350px;
}

.settings-box-title {
    font-size: 18px;
    margin-bottom: 5px;
}

.settings-box-subtitle {
    font-size: 14px;
}

.settings-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.settings-form-title {
    font-size: 15px;
    font-weight: 600;
}

.settings-form-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.settings-input-box {
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
}

.settings-input-label {
    font-size: 12px;
}

