:root {
    --main-blue: #063970;
    --main-blue-lighter: #074281;
    --main-white: white;
    --main-off-white: #06397007;
    --main-green: #13aa52;
    --main-red: rgb(139, 0, 0);
    --main-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto Condensed', Helvetica, sans-serif;

}


html, body {
    overscroll-behavior-y: none;
}

/* ********** SIDE BAR STYLES ***********  */

.sidebar-logo-container {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
    font-size: 14px;
    font-weight: bold;
    color: var(--main-white);
    border-bottom: 0.5px solid rgb(231, 228, 228);
    background-color: var(--main-blue);
}

.sidebar-links {
    list-style: none;
    padding: 5px 0;
    width: 100%;
}

.sidebar-a-tag {
    text-decoration: none;
    color: #063970;
}

.sidebar-link-title {
    font-size: 12px;
    font-weight: bold;
    color: var(--main-blue);
    margin: 20px 0 5px 15px;
}

.sidebar-link {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    margin: 0;
}

.sidebar-link:hover {
    background-color: var(--main-off-white);
}

span{
    font-size: 13px;
    font-weight: 600;
    color: var(--main-blue);
    margin-left: 10px;
}

/* ********** MAIN CONTENT STYLES TO SORT LAYOUT ********** */

.page-layout {
    display: flex;
}
  
.main-content {
      flex: 6;
      background-color: var(--main-off-white);
}

.main-content-inner-container {
    padding: 20px;
    
}

/* ********** PAGE TITLE AND BUTTON STYLES ********** */

.page-title-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;
    align-items: center;
}


.page-title-btns {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
} 

.page-title-btn {
    display: inline-block;
    padding: 5px 20px;
    border-radius: 3px;
    border: none;
    color: var(--main-white);
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
}

/* ********** BUTTON COLOURS STYLES ********** */

.btn-blue {
    background-color: var(--main-blue);
}

.btn-red {
    background-color: var(--main-red);
}

.btn-green {
    background-color: var(--main-green);
}

.no-style-btn {
    display: none;
    color: var(--main-white);
} 

/* ********** TABLE HEADER STYLES ********** */

.table-header {
    font-weight: bold;
}

/* ********** TABLE BUTTON STYLES ********** */

.table-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
}

.table-btn {
    border-radius: 3px;
    padding: 5px 5px;
    border: none;
    color: var(--main-white);
    cursor: pointer;
    font-size: 12px;
    flex: 1;
}

/* ********** TABLE STATUS STYLES ********** */

.SUCCESS {
    color: var(--main-green);
    font-weight: bold;
}

.PENDING {
    color: var(--main-red);
    font-weight: bold;
}

/* ********** POP UP STYLES ********** */

.pop-up-container {
    background-color: var(--main-white);
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 3px;
    margin: 10px 0;
    position: fixed;
    left: 50%;
    top: 140px;
    -ms-transform: translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 80%;
    max-width: 600px;
    z-index: 100;
}

.customer-pop-up{
    top: 40%;

}

.pop-up-close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    color: var(--main-white);
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    border: none;
}

.pop-up-content-box {
    position: relative;
}

/* ********** POP UP FORM STYLES *********** */

.pop-up-form-container { 
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pop-up-form-text {
    font-size: 14px;
}

.pop-up-input-box{
    border: 1px solid black;
    padding: 8px;
    border-radius: 3px;
    width: 100%;
}

.toast-input-box {
    margin: 7px 0;
}

.pop-up-btn {
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    border: none;
    color: var(--main-white);
    cursor: pointer;
    font-size: 12px;
    flex: 1;
}

/* ********** NOT FOUND STYLES *********** */

.notFound {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    color: #063970;
}
  
.underlined {
    text-decoration: underline;
    cursor: pointer;
}

/* ********** CALL BACK STYLES *********** */

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    gap: 10px;
}
    
.loading {
    font-size: large;
    font-weight: bold;
    padding-top: 5px;
}
    
.callback {
    width: 100%;    
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}
    
.icon {
    transform: scale(3);
    margin: 20px 0;
}
  
.responses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
    
.blue {
    color: var(--main-blue);
}
  
.message {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    max-width: 400px;
    padding: 0 30px;
}

.processing-message {
    font-size: 12px;
    text-align: center;
    max-width: 400px;
    padding: 0 30px;
}
    
.link {
    color: #ccc;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 30px;
}

/* ********* LOGIN SCREEN STYLES ********** */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: var(--main-blue);
}

.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
    padding: 0 20px 100px;
    width: 80%;
    max-width: 500px;
    color: var(--main-white);
}

.login-input-gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.settings-form-conatiner {
    width: 100%;
    max-width: 600px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.toast {
    position: fixed;
    z-index: 999;
}
  
.settings-table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
}

.settings-table-data, .settings-table-header {
    border-bottom: 1px solid #dddddd;
    padding: 15px 0;
}

.settings-logo {
    max-width: 300px;
}


.print-container{
    padding: 20px;
}

.title {
    margin-bottom: 10px;
}

.green {
    color: var(--main-green)
}

.blue {
    color: var(--main-blue);
}


