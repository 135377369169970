.MoreInfoContainer{
    height: 100vh;
    height: 100svh;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MoreInfoContainer > h4 {
    padding-bottom: 10px;
}