.link_boxes{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.outline-box {
    padding: 10px;
    box-shadow: var(--main-box-shadow);
    border-radius: 5px;
    min-width: 400px;
    flex: 1;
}

.qr-title {
    text-transform: uppercase;
    font-weight: 700;
    width: 300px;
}

.top-outline-box {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottom-outline-box {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: flex-end;
}

.input-box {
    flex: 10;
}


.input-label {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 5px;
}

.input {
    width: 100%;    
    border: 1px solid black;
    padding: 8px;
    border-radius: 3px;
}

.btn-text {
     color: white;
     cursor: pointer;
     min-width: 80px
}

.refresh {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}

.qr-icon {
    font-weight: 700;
    cursor: pointer;
}

.input_100{
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
    width: 100%;
}
