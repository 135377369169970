.csv_header_wrapper {
    border-bottom: 1px solid black;
    padding: 10px;
}

.csv_table {
    padding: 10px 10px 20px;
}

.csv_body {
    font-size: 14px;
}

.csv_header {
    text-align: start;
    padding: 10px 0;
}

.csv_loader {
    display: flex;
    justify-content: center;
}