.settings-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.settings-page-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employee-list {
   display: flex;
   gap: 10px;
   align-items: center;
   padding: 10px 0;
   justify-content: space-between;
   border-bottom: 0.5px solid #CCCCCC;
}
