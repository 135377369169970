.modal-style {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.modal-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-close-container{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-close{
    cursor: pointer;
    font-size: 20px;
}