.sub-heading {
    font-weight: 800;
    font-size: 20;
    margin-top: 1.4rem;
}

.para-heading {
    font-weight: 600;
    font-size: 18;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.list-container {
    margin: 0;
    padding-left: 0.6rem;
}

.list-item {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
}

.list-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
    margin-top: 1rem;
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 10px;
}

.chart-container {
    flex: 1;
    min-width: 500px;
    height: 400px;
    padding: 0;
    margin: 0;
    background-color: white;
    padding: 20px 0px 50px;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.chart-title {
    margin-left: 65px;
    margin-bottom: 20px;
}

.home-text {
     padding-top: 5px;
}

/* Totals Container */

.totals_container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.total_box {
    flex: 1;
    box-shadow: var(--main-box-shadow);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    gap: 10px;
    min-width: 343.34px;
}

/* Home Logo and Profile Section */

.home_logo {
    width: 100px;
    height: 100px;
    padding: 10px;
    box-shadow: var(--main-box-shadow);
    border-radius: 4px;
    object-fit: contain;
}

.home_profile_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.small_title {
    font-size: 12px;
}

.merchant_detail_text {
    font-size: 16px;
}

/* Latest News Container */

.news_container {
    box-shadow: var(--main-box-shadow);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.carousel_container {
    display: flex;
    gap: 30px;
}

/* Todays Summary */

.today_summary_container {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.today_stats_conatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.today_stat {
    text-align: center;
    padding: 10px;
    flex: 1;
    box-shadow: var(--main-box-shadow);
    margin-top: 10px;
    border-radius: 4px;
}

.stat {
    margin-top: 5px;
    color: var(--main-green);
    font-size: 20px;
}

