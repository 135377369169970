/* Main Styles for Merchant Dashboard */

.dashboard_container {
    display: flex;
}

.main_content_container {
    display: flex;
    flex-direction: column;
    gap: 10;
    padding: 20px;
    width: 100%;
}

.main-content {
    background-color: white;
    padding: 1.5rem 2rem;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    min-width: 900px;

}

.page-title {
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    font-size: 1.6rem;
    
}

.page-header-buttons {
    display: flex;
    gap: 10px;
}

.header-button {
    font-size: 1.6rem;
    cursor: pointer;
}

.input-new {
    border: 1px solid black;
    padding: 6px;
    border-radius: 3px;
    width: 100%;
}

.button-new {
    border: 1px solid black;
    padding: 6px;
    border-radius: 3px;
    width: 100%;
    min-width: 350px;
    background-color: var(--main-green);
    color: white;
    cursor: pointer;
}

.select-box {
    font-size: 12px;
}

.showStatus {
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;

}

.status-text {
    padding: 0;
}

.pageLoaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.toggle_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0px;
    padding: 1px 10px 0px 10px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
}

/* CSS */
.button-37 {
  background-color: var(--main-green);
  border: 1px solid var(--main-green);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 6px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

  .checkbox-wrapper-6 .tgl {
    display: none;
  }
  .checkbox-wrapper-6 .tgl,
  .checkbox-wrapper-6 .tgl:after,
  .checkbox-wrapper-6 .tgl:before,
  .checkbox-wrapper-6 .tgl *,
  .checkbox-wrapper-6 .tgl *:after,
  .checkbox-wrapper-6 .tgl *:before,
  .checkbox-wrapper-6 .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .checkbox-wrapper-6 .tgl::-moz-selection,
  .checkbox-wrapper-6 .tgl:after::-moz-selection,
  .checkbox-wrapper-6 .tgl:before::-moz-selection,
  .checkbox-wrapper-6 .tgl *::-moz-selection,
  .checkbox-wrapper-6 .tgl *:after::-moz-selection,
  .checkbox-wrapper-6 .tgl *:before::-moz-selection,
  .checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
  .checkbox-wrapper-6 .tgl::selection,
  .checkbox-wrapper-6 .tgl:after::selection,
  .checkbox-wrapper-6 .tgl:before::selection,
  .checkbox-wrapper-6 .tgl *::selection,
  .checkbox-wrapper-6 .tgl *:after::selection,
  .checkbox-wrapper-6 .tgl *:before::selection,
  .checkbox-wrapper-6 .tgl + .tgl-btn::selection {
    background: none;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 3em;
    height: 1.6em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:after,
  .checkbox-wrapper-6 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:after {
    left: 0;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:before {
    display: none;
  }
  .checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .checkbox-wrapper-6 .tgl-light + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
  }
  .checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
  }
  .checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
    background: #9FD6AE;
  }

.copy_icon {
  cursor: pointer;
  color: var(--main-blue);
}

.print_icon {
  cursor: pointer;
  color: var(--main-blue);
}