.terminalButtonFlex {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.termanlBtn {
     flex: 1;
}

.terminal_form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.paymentTypeRadioBtns {
    display: flex;
    gap: 20px;
}

.terminal-header-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
}

.terminal-payment-form{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.terminal-radio-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}


.terminal-form-inputs {
    display: flex;
    gap: 10px;
    width: 100%;

}

.terminal-form-input {
    flex: 1;
    margin-top: 5px;
}

.terminal-form-btn {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    gap: 10px;
}

.inputStyle100W {
    margin: 0px;
    padding: 1px 5px 1px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
    width: 100%;
    margin-top: 5px;
}