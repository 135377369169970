.customer-inputs {
    display: flex;
    gap: 15px;
    justify-content:space-between;
    font-size: 20px;
}

.customer-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    min-width: 200px;
}

.delete-icon {
    color: var(--main-red);
    cursor: pointer;
  
}

.info-icon {
    color: var(--main-green);
    cursor: pointer;
    margin-bottom: 2.5px;
}

.pay-icon {
    cursor: pointer;
    margin-bottom: 2.5px;
}

.tbl-icon:hover {
    transform: rotate(10deg);
}

.searchBox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;
}

.searchInput {
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
    width: 250px;
}

.searchSelection {
    min-width: 150px;
    font-size: 12px;
    padding: 0;
    z-index: 100;
}

.table-title {
    font-weight: 700;
    padding: 10px 0
}

.vl {
    border-left: 1.5px solid #B7B7B7;
    height: 35px;
    margin: 0;
    padding-right: 8px;
  }

  .arrows {
    display: flex;
    flex-direction: column;
    color: #B7B7B7;
    cursor: pointer;
  }

  .arrow-active {
    color: black;
  }

  .format-row {
    text-transform: capitalize;
  }

  .payment-btn {
    border-radius: 3px;
    padding: 5px 5px;
    border: none;
    color: var(--main-white);
    cursor: pointer;
    font-size: 12px;
    flex: 1;
    max-width: 100px;
    background-color: var(--main-blue);
    min-width: 100px;
  }

  .quick-add-container {
    display: flex;
    gap: 2px;
  }

  .quick-add-btn {
    font-size: 10px;
    padding: 0 5px;
    cursor: pointer;
    background-color: white;
    color: #808080;
    border: 1px solid #CCCCCC;
  }

  /* MODAL STYLES */

  .modalFormContainer {
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 10px;
  }

  .modalFormInputBox {
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;

}

.modalFormAdditionalInfo {
  font-size: 11px;
}

.modalFormInputButton {
    margin: 0px;
    padding: 1px 5px 0px 5px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    height: 38px;
    cursor: pointer;
}

.modalFormInputButton:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.quickAdd {
  text-decoration: underline;
  cursor: pointer;
}


.table_conatiner {
  box-shadow:  var(--main-box-shadow);
  border-radius: 4px 4px 0 0;
  
}

.table_options {
  box-shadow: var(--main-box-shadow);
  border-radius: 0 0 4px 4px;
}