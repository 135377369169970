.uws-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.uws-logo {
    width: 150px;
    margin-bottom: 5px;
}

.uws-title {
    margin-bottom: 20px;
    font-size: 14px;
}

.uws-inputs {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 400px;
    gap: 10px;
}

.uws-inputs > input, select {
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 16px;
}

.uws-inputs > button {
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: #ED1B24;
    color: white;
    font-size: 16px;
}

.uws-input-title {
    font-size: 15px;
}

.uws-input-text {
    font-size: 10px;
}

.bankCheck {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    font-size: 12px;
}

.bankCheckImg {
    width: 200px;
    margin-top: 20px;
}