.sidebar {
    border-right: 0.5px solid rgb(230, 227, 227);
    height: 100vh;
    width: 230px;
    min-width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow: none;
    justify-content: space-between;
}

.logoContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo {
    width: 7rem;
    margin-bottom: 20px;
    cursor: pointer;
    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   object-fit: contain;
}

.sidebar_line_divider {
    border-top: 1px solid rgb(230, 227, 227);
    width: 100%;
    margin-bottom: 20px;
}

.sidebar_options_container {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sidebar_option {
    display: flex;
    gap: 15px;
    padding: 11px 27px;
    border-radius: 5px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.sidebar_option:hover {
    background-color: aliceblue;
    color: black;
}

.sidebar_option_active {
    display: flex;
    gap: 15px;
    padding: 11px 27px;
    border-radius: 5px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    background-color: var(--main-blue-lighter);
    color: white;
}

.submitRequest {
    float: right;
    font-size: 12px;
}